'use client';

import IconButton from '@/components/Button/IconButton';
import EmojiText from '@/components/EmojiText/EmojiText';
import Section from '@/components/Section/Section';
import { useChapterProgress } from '@/hooks/useChapterProgress';
import { Job, Jobs } from '@/resources/jobs';
import { focusOnArticle } from '@/utils/focus';
import { capitalizeFirstLetter } from '@/utils/strings';
import { Dialog, Transition } from '@headlessui/react';
import {
	mdiBookmarkCheck,
	mdiClose,
	mdiMenu,
	mdiOpenInNew,
	mdiStar,
} from '@mdi/js';
import Icon from '@mdi/react';
import { motion, useReducedMotion } from 'motion/react';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useLayoutEffect, useMemo, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { NavigationItem } from './NavigationItem';

const DetailNavigation = ({
	detailNavigationTree,
}: {
	detailNavigationTree: NavigationItem;
}) => {
	const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);

	if (
		!detailNavigationTree?.children?.length ||
		detailNavigationTree.metadata?.hideFromNavigation
	)
		return <></>;

	return (
		<>
			<DetailNavigationContent
				detailNavigationTree={detailNavigationTree}
				className="sticky top-0 hidden md:block"
			/>
			<Section as="div" className="p-4 md:hidden">
				<button
					className="grid w-full items-stretch gap-4"
					aria-label="Open menu"
					onClick={() => setIsMobileMenuOpened(true)}
				>
					<IconButton
						isLarge
						label={
							detailNavigationTree?.metadata?.title ??
							'Inhoudsopgave'
						}
						icon={mdiMenu}
						title={
							detailNavigationTree?.metadata?.title
								? `Inhoudsopgave van ${detailNavigationTree?.metadata?.title} openen`
								: 'Inhoudsopgave openen'
						}
						className="mx-auto w-full max-w-sm"
						isDecorationOnly
					/>
					<ChapterProgressRingsView
						detailNavigationTree={detailNavigationTree}
					/>
				</button>
			</Section>
			<Dialog
				className="md:hidden"
				open={isMobileMenuOpened}
				onClose={() => setIsMobileMenuOpened(false)}
			>
				<Transition
					show={isMobileMenuOpened}
					enter="transition duration-300 ease-out"
					enterFrom="motion-safe:-translate-x-full opacity-0"
					enterTo="opacity-100"
					leave="transition duration-300 ease-out"
					leaveFrom="opacity-100"
					leaveTo="motion-safe:-translate-x-full opacity-0"
					className="absolute inset-0 z-50 h-full shadow-2xl"
				>
					<Dialog.Panel className="fixed inset-0 size-full md:hidden">
						<DetailNavigationContent
							detailNavigationTree={detailNavigationTree}
							className="absolute inset-0 z-50"
							withOnClose={() => setIsMobileMenuOpened(false)}
						/>
					</Dialog.Panel>
				</Transition>
			</Dialog>
		</>
	);
};

const DetailNavigationContent = ({
	detailNavigationTree,
	className = '',
	withOnClose,
}: {
	detailNavigationTree: NavigationItem;
	className?: string;
	withOnClose?: () => void;
}) => {
	const pathname = usePathname() ?? '';

	const mainHref = `/${pathname.split('/').at(1)}`;

	const mainDirectory = useMemo(() => {
		return (
			detailNavigationTree?.metadata?.title ??
			capitalizeFirstLetter(detailNavigationTree.name ?? '')
		);
	}, [pathname]);

	const job: Job | undefined = Jobs.find(
		(job) => job.id === pathname.split('/').at(1),
	);

	const extraNavigationItemNoInteractives = {
		interactives: {
			quizzes: [],
		},
	};
	const extraNavigationItems: NavigationItem[] = [
		{
			name: `richtlijnen`,
			parentPath: '/',
			opensInNewTab: job
				? `/richtlijnen?professions=${job?.id}`
				: '/richtlijnen',
			metadata: {
				title: job ? 'Mijn richtlijnen' : 'Richtlijnen',
				emoji: job ? '😇' : '📖',
				sort: detailNavigationTree?.children?.length ?? 0 + 1,
			},
			...extraNavigationItemNoInteractives,
		},
		{
			name: `contrast`,
			parentPath: '/',
			opensInNewTab: `/contrast`,
			metadata: {
				title: 'Contrast meten',
				emoji: '🍭',
				sort: detailNavigationTree?.children?.length ?? 0 + 1,
			},
			...extraNavigationItemNoInteractives,
		},
		{
			name: `simuleren`,
			parentPath: '/',
			opensInNewTab: `/simuleren`,
			metadata: {
				title: 'Simuleren',
				emoji: '🔮',
				sort: detailNavigationTree?.children?.length ?? 0 + 1,
			},
			...extraNavigationItemNoInteractives,
		},
		{
			name: `nieuws`,
			parentPath: '/',
			opensInNewTab: `/nieuws`,
			metadata: {
				title: 'Nieuws',
				emoji: '📰',
				sort: detailNavigationTree?.children?.length ?? 0 + 1,
			},
			...extraNavigationItemNoInteractives,
		},
	];

	return (
		<div className="top-4 h-full md:sticky">
			<Section
				as="aside"
				className={`relative size-full shrink-0 bg-primary-50 max-md:after:opacity-0 md:w-64 dark:bg-slate-900 ${className} overflow-hidden`}
			>
				<div className="relative max-h-screen overflow-y-auto pb-2">
					{!withOnClose && (
						<>
							<button
								className="sr-only rounded-full text-lg font-bold focus:not-sr-only focus:m-4 focus:p-4"
								onClick={focusOnArticle}
							>
								Direct naar artikel
							</button>
						</>
					)}
					<nav
						aria-label="Sub-pagina's"
						className="flex flex-col justify-center pb-4 text-slate-950 dark:text-slate-50"
					>
						<div className="z-10 mb-2 flex items-center border-b-2 border-b-primary-500/20 bg-primary-50 md:sticky md:top-0 dark:border-b-white/25 dark:bg-slate-900">
							<Link
								href={mainHref}
								className="group m-2 flex grow flex-col gap-2 rounded-lg px-2 py-4 text-2xl font-bold transition-all duration-300 active:bg-primary-500/0 hover:bg-primary-200 md:text-center dark:hover:bg-primary-700"
							>
								<ChapterProgressRingsView
									detailNavigationTree={detailNavigationTree}
								/>
								<div className="mt-4 flex items-center justify-between md:justify-center">
									{mainDirectory}
									{!!withOnClose && (
										<IconButton
											label="Sluit"
											icon={mdiClose}
											title="Inhoudsopgave sluiten"
											onClick={(event) => {
												event.preventDefault();
												withOnClose();
											}}
											className="shrink-0"
										/>
									)}
								</div>
							</Link>
						</div>
						{!detailNavigationTree?.children?.length ? (
							<p>Geen sub-pagina&apos;s</p>
						) : (
							<ul
								role="tree"
								aria-label={`Inhoudsopgave van ${mainDirectory}`}
								className="flex flex-col md:text-lg"
							>
								{detailNavigationTree.children.map(
									(childNode) => {
										if (!childNode) return <></>;
										return (
											<PageListItem
												key={childNode.name}
												parentPath={`/${
													pathname.split('/').at(1) ??
													''
												}`}
												navigationItem={childNode}
											/>
										);
									},
								)}
								<div
									aria-hidden
									className="my-2 border-b-2 border-b-primary-500/20 dark:border-b-white/25"
								/>
								{extraNavigationItems.length > 0 && (
									<li
										role="treeitem"
										aria-selected={false}
										aria-checked={false}
										aria-level={1}
									>
										<ul
											aria-label="Meer pagina's"
											role="tree"
										>
											{extraNavigationItems.map(
												(navigationItem) => (
													<PageListItem
														key={
															navigationItem.name
														}
														parentPath={`/${
															pathname
																.split('/')
																.at(1) ?? ''
														}`}
														navigationItem={
															navigationItem
														}
													/>
												),
											)}
										</ul>
									</li>
								)}
							</ul>
						)}
					</nav>
				</div>
			</Section>
		</div>
	);
};

const PageListItem = ({
	parentPath,
	navigationItem,
}: {
	parentPath: string;
	navigationItem: NavigationItem;
}) => {
	const pathname = usePathname() ?? '';
	const level = parentPath.split('/').length;
	const targetPathname = `${parentPath}/${navigationItem.name}`;
	const displayName =
		navigationItem?.metadata?.title ??
		capitalizeFirstLetter(navigationItem.name).replaceAll('-', ' ');
	const isActive = pathname === targetPathname;
	const isGroupActive = isActive || pathname.startsWith(targetPathname);

	const pageHref = navigationItem.opensInNewTabOnly
		? navigationItem.name
		: targetPathname;

	useLayoutEffect(() => {
		if (!isActive) return;
		// Scroll to last element with aria-selected=true in the aside element
		requestAnimationFrame(() => {
			const activeElement = document.querySelector(
				'aside [aria-selected="true"]',
			);
			if (activeElement) {
				// If not in view
				if (
					activeElement.getBoundingClientRect().top < 0 ||
					activeElement.getBoundingClientRect().bottom >
						window.innerHeight
				) {
					// Scroll to center
					activeElement.scrollIntoView({
						block: 'center',
					});

					// Scroll main content to top (parent of aside element)
					const bodyTop = document.querySelector('body')?.firstChild;
					if (bodyTop) {
						setTimeout(() => {
							// @ts-expect-error scrollIntoView is not in the types
							bodyTop.scrollIntoView({
								block: 'start',
							});
						}, 200);
					}
				}
			}
		});
	}, [isActive]);

	return (
		<li
			role="treeitem"
			aria-selected={isActive}
			aria-checked={isActive}
			className={`me-2 ${
				isGroupActive && !isActive
					? 'shadow-border-bottom-sm bg-white shadow-md dark:bg-primary-950'
					: ''
			} ${
				isActive
					? 'shadow-border-bottom-sm bg-primary-100 font-bold shadow-sm dark:bg-primary-800'
					: 'font-semibold'
			} rounded-e-3xl text-primary-900 transition-all duration-300 after:rounded-e-3xl dark:text-primary-100`}
			aria-level={level}
		>
			<div className="flex items-center">
				<Link
					href={pageHref}
					target={
						navigationItem.opensInNewTabOnly ? '_blank' : undefined
					}
					className={`group relative flex w-full grow items-center py-2 pe-2 ps-4 ${
						level > 2 ? 'border-s-2 border-primary-400' : ''
					} rounded-e-3xl transition-all duration-300 hover:bg-primary-100 dark:hover:bg-primary-700`}
					role="link"
				>
					<ChapterPageProgressView
						pagePath={targetPathname.replace('/', '')}
						detailNavigationTree={navigationItem}
					/>
					<span
						className="mr-2.5 inline-flex select-none items-center justify-center"
						aria-hidden
					>
						<EmojiText>
							{navigationItem?.metadata?.emoji ?? '📄'}
						</EmojiText>
					</span>
					{displayName}
					{isActive && (
						<span className="sr-only"> (huidige pagina)</span>
					)}
				</Link>
				{navigationItem.opensInNewTab && (
					<button
						className="-ms-2 me-0.5 shrink-0 rounded-full p-3 text-opacity-75 transition-all hover:bg-primary-700 hover:text-white hover:text-opacity-100 hover:shadow-lg motion-safe:active:scale-100 motion-safe:hover:scale-110"
						onClick={(event) => {
							event.preventDefault();
							window.open(navigationItem.opensInNewTab, '_blank');
						}}
						title="Open in een nieuw tabblad"
						aria-label={`Open '${displayName}' in een nieuw tabblad`}
						type="button"
						role="link"
					>
						<span aria-hidden>
							<Icon path={mdiOpenInNew} size={0.75} />
						</span>
					</button>
				)}
			</div>
			{!!navigationItem.children?.length && (
				<ul
					role="group"
					className="pb-2 ps-4 text-base font-normal"
					aria-label={`Inhoudsopgave van ${navigationItem.name.replaceAll(
						'-',
						'',
					)}`}
				>
					{navigationItem.children.map((childNode) => (
						<PageListItem
							key={childNode.name}
							parentPath={`${parentPath}/${navigationItem.name}`}
							navigationItem={childNode}
						/>
					))}
				</ul>
			)}
		</li>
	);
};

const ChapterPageProgressView = ({
	pagePath,
	detailNavigationTree,
}: {
	pagePath: string;
	detailNavigationTree: NavigationItem;
}) => {
	const { isPageCompleted } = useChapterProgress(
		pagePath.split('/')[0],
		detailNavigationTree,
	);

	// Only show after first render, to prevent "html doesn't match server" error
	const [showElement, setShowElement] = useState<boolean>(false);

	useLayoutEffect(() => {
		setShowElement(true);
	}, []);

	if (!showElement || !isPageCompleted(pagePath)) return <></>;

	return (
		<div className="absolute start-0 top-1/2 h-4 w-1 -translate-y-1/2 rounded-r-[0.2rem] bg-quinary transition-all dark:bg-quinary-300">
			<span className="sr-only">(Gelezen)</span>
		</div>
	);
};

const ChapterProgressRingsView = ({
	detailNavigationTree,
}: {
	detailNavigationTree: NavigationItem;
}) => {
	const reduceMotion = useReducedMotion();

	const {
		pagesProgressPercentage,
		quizzesProgressPercentage,
		isChapterCompleted,
	} = useChapterProgress(detailNavigationTree.name, detailNavigationTree);

	// Only show after first render, to prevent "html doesn't match server" error
	const [showElement, setShowElement] = useState<boolean>(false);

	useLayoutEffect(() => {
		setShowElement(true);
	}, []);

	if (!showElement) return <></>;

	return (
		<>
			<section
				aria-hidden
				title={`${pagesProgressPercentage}% van de pagina's gemarkeerd als voltooid`}
				className={`shadow-border-bottom-sm relative mx-auto my-2 flex size-44 items-center justify-center ${
					isChapterCompleted
						? 'bg-amber-400/10'
						: 'bg-white dark:bg-slate-700'
				} emoji-illustration-container rounded-full shadow-md transition-all duration-500 after:rounded-full group-hover:shadow-lg motion-safe:group-active:scale-95 motion-safe:group-hover:scale-105`}
			>
				<div aria-hidden>
					<CircularProgressbar
						className={`absolute inset-0 size-full ${
							quizzesProgressPercentage > 0
								? 'text-quinary-500 dark:text-quinary-300'
								: 'text-primary-500 dark:text-primary-300'
						} transition-colors duration-500`}
						value={pagesProgressPercentage}
						strokeWidth={8}
						styles={{
							path: {
								stroke: 'currentColor',
							},
							trail: {
								stroke: 'rgba(0, 0, 0, 0.05)',
							},
						}}
					/>
					<CircularProgressbar
						className="absolute inset-0 top-[0.88rem] size-[9.24rem] text-quaternary-500 dark:text-quaternary-300"
						value={quizzesProgressPercentage}
						strokeWidth={9}
						styles={{
							path: {
								stroke: 'currentColor',
							},
							trail: {
								stroke:
									quizzesProgressPercentage > 0
										? 'rgba(0, 0, 0, 0.025)'
										: 'transparent',
							},
						}}
					/>
				</div>
				<motion.p
					className="select-none text-5xl"
					aria-hidden
					initial={{
						rotate: isChapterCompleted ? 354 : 0,
					}}
					animate={{
						translateX: isChapterCompleted ? '-30%' : 0,
						scale: isChapterCompleted ? 0.8 : 1,
						rotate: isChapterCompleted ? 354 : 0,
						opacity: isChapterCompleted ? [1, 0, 1] : 1,
						transition: {
							duration: reduceMotion ? 0.01 : 0.5,
						},
					}}
				>
					<EmojiText>
						{detailNavigationTree.metadata?.emoji ?? '📄'}
					</EmojiText>
				</motion.p>
				<motion.p
					className="absolute select-none text-5xl"
					aria-hidden
					initial={{
						opacity: isChapterCompleted ? 1 : 0,
						scale: 0.5,
					}}
					animate={{
						translateX: isChapterCompleted ? '20%' : 0,
						scale: isChapterCompleted ? 1.15 : 0,
						opacity: isChapterCompleted ? 1 : 0,
						rotate: isChapterCompleted ? 6 : -40,
						transition: {
							duration: reduceMotion ? 0.01 : 0.75,
						},
					}}
				>
					<EmojiText>🏆</EmojiText>
				</motion.p>
			</section>

			<ChapterProgressTextView
				detailNavigationTree={detailNavigationTree}
			/>
		</>
	);
};

interface ChapterProgressChip {
	label: string;
	icon: string;
	color: string;
	percentage: number;
}

const ChapterProgressTextView = ({
	detailNavigationTree,
}: {
	detailNavigationTree: NavigationItem;
}) => {
	const { pagesProgressPercentage, quizzesProgressPercentage } =
		useChapterProgress(detailNavigationTree.name, detailNavigationTree);

	// Only show after first render, to prevent "html doesn't match server" error
	const [showElement, setShowElement] = useState<boolean>(false);

	useLayoutEffect(() => {
		setShowElement(true);
	}, []);

	if (!showElement) return <></>;

	const progressChips: ChapterProgressChip[] = [
		{
			label: "Pagina's",
			icon: mdiBookmarkCheck,
			color:
				quizzesProgressPercentage > 0
					? 'bg-quinary-500 dark:bg-quinary-300 dark:text-quinary-950'
					: 'bg-primary-500 dark:bg-primary-200 dark:text-primary-950',
			percentage: pagesProgressPercentage,
		},
	];

	if (quizzesProgressPercentage > 0) {
		progressChips.push({
			label: 'Quiz',
			icon: mdiStar,
			color: 'bg-quaternary-500 dark:bg-quaternary-300 dark:text-quaternary-950',
			percentage: quizzesProgressPercentage,
		});
	}

	const percentageOfTotal = Math.round(
		progressChips.reduce((acc, chip) => acc + chip.percentage, 0) /
			progressChips.length,
	);

	if (percentageOfTotal === 0) {
		return <></>;
	}

	return (
		<div
			aria-label={`Voortgang: ${progressChips
				.map((chip) => `${chip.label}: ${chip.percentage}%`)
				.join(', ')}`}
			className="flex flex-wrap justify-center gap-1"
		>
			{progressChips.map((chip, index) => (
				<div
					key={index}
					className={`shadow-border-bottom-sm flex items-center gap-1.5 rounded-full py-1 pe-3 ps-2 text-base font-bold text-white shadow-md after:rounded-full ${chip.color}`}
					title={`${chip.label}: ${chip.percentage}%`}
				>
					<span className="sr-only">{chip.label}: </span>
					<Icon path={chip.icon} size={0.8} />
					<span>{chip.percentage}%</span>
				</div>
			))}
		</div>
	);
};

export default DetailNavigation;
